import React, { useState } from 'react';
import Waves from '../components/waves';
import Navigation from '../components/navigation';

import Footer from '../components/footer';

import '../css/typography.css';
import { Link } from "gatsby";

const Booking = () => {
    const [isDC, setIsDC] = useState(true);

    function handleSetDC() {
        setIsDC(true);
    }

    function handleSetColumbia() {
        setIsDC(false);
    }

    const buttonCls = "quicksand ml-4 font-bold rounded-full my-6 ml-2 py-2 px-6 shadow-lg ";
    const dcButtonCls = `${buttonCls} + ${isDC ? "selectedLocation text-white" : "bg-gray-200 text-gray-800"}`
    const columbiaButtonCls = `${buttonCls} + ${isDC ? "bg-gray-200 text-gray-800" : "selectedLocation text-white"}`

    return (
        <body className="leading-normal tracking-normal text-white gradient">
            <Navigation showButton={false} />
            <div className="pt-4">
                <Waves />
            </div>

            <section className="bg-white">

                <div className="container max-w-5xl mx-auto">
                    <button onClick={() => handleSetDC()} className={dcButtonCls} href="#">
                        Washington&nbsp;DC
                    </button>
                    <button onClick={() => handleSetColumbia()} className={columbiaButtonCls} href="#">
                        Columbia&nbsp;MD
                    </button>
                </div>


                { isDC ?
                    <iframe
                        scrolling="no"
                        className="overflow-auto container max-w-5xl h-screen mx-auto spinner"
                        src="https://squareup.com/appointments/book/de2bzajlpbc5of/80QVMSMT3RY16/start"
                        title="booking" /> :
                    <iframe
                        scrolling="no"
                        className="overflow-auto container max-w-5xl h-screen mx-auto spinner"
                        src="https://app.squareup.com/appointments/book/de2bzajlpbc5of/LD8D9H8RX7G4Q/start"
                        title="bookingColumbia" />

                }
            </section>

            {/*<div className="py-10 bg-white" />*/}
            {/*<Footer />*/}
        </body>
    )
}

export default Booking;
